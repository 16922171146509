import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/jobinterview',
    name: 'JobInterview',
    meta: {
      title: 'Job Interview Prep',
    },
    component: () =>
      import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/JobInterview/views/JobInterview.vue'),
  },
];

export default routes;
