import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

import moment from 'moment';

import { api } from '@/api/api';
import { RootState } from '../types';

const state: unknown = {
  usages: {
    streakDays: 0,
    daysOfWeek: [
      { name: 'Monday' },
      { name: 'Tuesday' },
      { name: 'Wednesday' },
      { name: 'Thursday' },
      { name: 'Friday' },
      { name: 'Saturday' },
      { name: 'Sunday' },
    ],
  },
  assessment: null,
  minOfRecord: 0,
  freeMinOfRecord: 0,
  ielts: [],
  featured: [],
  courses: [],
  newYearChallenge: [],
  newYearFolder: {},
  passed_assessment: false,
  vocabooster_count: 0,
  webinarBannerHide: true,
};

const actions: ActionTree<unknown, RootState> = {
  async fetchStats({ commit }, payload) {
    const {
      usages,
      assessment,
      passed_assessment,
      total_duration_seconds,
      total_free_talk_duration_seconds,
      vocabooster_count,
    } = await api.getDashboardInfo();
    commit('usages', usages);
    commit('minOfRecord', (total_duration_seconds / 60).toFixed(0));
    commit('freeMinOfRecord', (total_free_talk_duration_seconds / 60).toFixed(0));
    commit('assessment', assessment);
    commit('passed_assessment', passed_assessment);
    commit('vocabooster_count', vocabooster_count);
  },
  async fetchFeatured({ commit }) {
    const { folders, variants, folders_progress } = await api.getProblemsFoldersInfo('c9elugfvpg2cn91qid10');

    const originData = folders.length ? folders : variants;
    commit(
      'featured',
      originData.map((element) =>
        Object.assign(element, {
          progress: folders_progress[element.id] ? folders_progress[element.id].completed_variants : 0,
        }),
      ),
    );
  },
  async fetchNewYearChallenge({ commit }) {
    const { folder, folders, variants, variants_progress } = await api.getProblemsFoldersInfo('ccm9faauo5536ncocj1g');

    const originData = folders.length ? folders : variants;
    commit(
      'newYearChallenge',
      originData.map((element) =>
        Object.assign(element, {
          completed: variants_progress[element.id] ? variants_progress[element.id].completed : false,
        }),
      ),
    );
    commit('newYearFolder', folder);
  },
  async fetchIELTS({ commit }) {
    const { folders, variants, folders_progress } = await api.getProblemsFoldersInfo('bvfm48uughbpak0t6ok0');
    const originData = folders.length ? folders : variants;
    const data = originData.reduce((acc, curr) => {
      for (let i = 0; i < curr.freeVariantsCount; i++) {
        acc.push({ id: curr.content.variantIds[i], title: curr.content.variantTitles[i] });
      }
      return acc;
    }, []);

    commit(
      'ielts',
      data.map((element) =>
        Object.assign(element, {
          progress: folders_progress[element.id] ? folders_progress[element.id].completed_variants : 0,
        }),
      ),
    );
  },

  async fetchCourses({ commit }) {
    const { folders, variants, folders_progress } = await api.getProblemsFoldersInfo('c8f4mi9g6uncgqjvghf0');
    const originData = folders.length ? folders : variants;

    commit(
      'courses',
      originData.map((element) =>
        Object.assign(element, {
          progress: folders_progress[element.id] ? folders_progress[element.id].completed_variants : 0,
        }),
      ),
    );
  },
};

const mutations: MutationTree<unknown> = {
  usages: (state, payload) => {
    const currDay: number = new Date().getDay() === 0 ? 7 : new Date().getDay();

    const daysOfWeek = [
      { name: 'Monday', class: setClass(1) },
      { name: 'Tuesday', class: setClass(2) },
      { name: 'Wednesday', class: setClass(3) },
      { name: 'Thursday', class: setClass(4) },
      { name: 'Friday', class: setClass(5) },
      { name: 'Saturday', class: setClass(6) },
      { name: 'Sunday', class: setClass(7) },
    ];

    function isDateInArray(needle, haystack) {
      for (let i = 0; i < haystack.length; i++) {
        if (moment(needle).format('YYYY-MM-DD') === moment(haystack[i]).format('YYYY-MM-DD')) {
          return true;
        }
      }
      return false;
    }

    const uniqueDates = [];
    for (let i = 0; i < payload.length; i++) {
      if (!isDateInArray(new Date(payload[i]), uniqueDates)) {
        uniqueDates.push(new Date(payload[i]));
      }
    }

    const currDayOfWeek = moment().day();
    let daysOffset = 0;

    const streakDates = uniqueDates.reverse().reduce((acc, curr, index) => {
      const requiredDate = moment().subtract(daysOffset, 'day');

      const isThisWeek = moment(curr).isBetween(
        moment().subtract(currDayOfWeek === 0 ? 7 : currDayOfWeek, 'day'),
        moment().add(currDayOfWeek ? 7 - currDayOfWeek : 0, 'day'),
      );
      if (isThisWeek) {
        const iMomentDay = moment(curr).day();
        const transformedDays = iMomentDay === 0 ? 7 : iMomentDay;

        daysOfWeek[transformedDays - 1].class = {
          ...daysOfWeek[transformedDays - 1].class,
          'circle-visit': true,
        };
      }

      if (requiredDate.isSame(curr, 'day')) {
        daysOffset += 1;
        acc.push(curr);
        return acc;
      }

      if (moment(curr).isSame(requiredDate.subtract(1, 'day'), 'day') && index === 0) {
        daysOffset += 2;

        acc.push(curr);
      }

      return acc;
    }, []);

    function setClass(i: number) {
      return {
        circle: true,
        'circle-past': i < currDay,
        'circle-future': i > currDay,
        'circle-present': i === currDay,
      };
    }

    state.usages = { streakDays: streakDates.length, daysOfWeek };
  },
  minOfRecord: (state, payload) => {
    state.minOfRecord = payload;
  },
  freeMinOfRecord: (state, payload) => {
    state.freeMinOfRecord = payload;
  },
  vocabooster_count: (state, payload) => {
    state.vocabooster_count = payload;
  },
  assessment: (state, payload) => {
    state.assessment = payload;
  },
  passed_assessment: (state, payload) => {
    state.passed_assessment = payload;
  },
  featured: (state, payload) => {
    state.featured = payload;
  },
  newYearChallenge: (state, payload) => {
    state.newYearChallenge = payload;
  },
  newYearFolder: (state, payload) => {
    state.newYearFolder = payload;
  },
  ielts: (state, payload) => {
    state.ielts = payload;
  },
  courses: (state, payload) => {
    state.courses = payload;
  },
  webinarBannerHide: (state, payload) => {
    state.webinarBannerHide = payload;
  },
};

const getters: GetterTree<unknown, RootStates> = {
  usages(state): Array<string> {
    return state.usages;
  },
  minOfRecord(state): string {
    return state.minOfRecord;
  },
  freeMinOfRecord(state): string {
    return state.freeMinOfRecord;
  },
  vocabooster_count(state): string {
    return state.vocabooster_count;
  },
  assessment(state): Array<object> {
    return state.assessment;
  },
  passed_assessment(state): boolean {
    return state.passed_assessment;
  },
  featured(state): Array<object> {
    return state.featured;
  },
  newYearChallenge(state): Array<object> {
    return state.newYearChallenge;
  },
  newYearFolder(state): Array<object> {
    return state.newYearFolder;
  },
  ielts(state): Array<object> {
    return state.ielts;
  },
  courses(state): Array<object> {
    return state.courses;
  },
  webinarBannerHide(state): boolean {
    return state.webinarBannerHide;
  },
};

const index: Module<unknown, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};

export default index;
