import { RouteConfig } from 'vue-router/types/router';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import authRoutes from '@/views/Auth/router';
import organizationsAuthRoutes from '@/views/Organizations/Auth/router';
import createBusinessRouteRoutes from '@/views/Organizations/Business/router';
import dashboardRoutes from '@/views/Dashboard/router';
import legalRoutes from '@/views/Legal/router';
import adminRoutes from '@/views/Admin/router';
import paymentsRoutes from '@/views/Pricing/Payments/router';
import questionnaireRoutes from '@/views/Questionnaire/router';
import shareRoutes from '@/views/Report/SharedReport/router';
import pricingRoutes from '@/views/Pricing/PricingPlan/router';
import topicsRoutes from '@/views/AITopics/router';
import certificateRoutes from '@/views/CertificatePage/router';
import profile from '@/views/Profile/router';
import { isAuth } from '@/helpers/auth';
import testingRoutes from '@/views/Testing/router';
import specialOfferRoutes from '@/views/SpecialOffer/router';

export default (apolloProvider: ApolloClient<NormalizedCacheObject>): Array<RouteConfig> => [
  ...authRoutes,
  ...organizationsAuthRoutes,
  ...createBusinessRouteRoutes(apolloProvider),
  ...dashboardRoutes,
  ...legalRoutes,
  ...adminRoutes,
  ...paymentsRoutes,
  ...questionnaireRoutes,
  ...shareRoutes,
  ...topicsRoutes,
  ...pricingRoutes,
  ...certificateRoutes,
  ...testingRoutes,
  ...profile,
  ...specialOfferRoutes,
  {
    path: '/',
    name: 'Home',
    redirect: '/business',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '*',
    name: 'error',
    redirect: { name: 'notFound' },
  },
  {
    path: '/auth-error',
    name: 'AuthError',
    component: () => import('../views/Errors/AuthError.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('../views/Errors/Error.vue'),
    beforeEnter: (to, from, next) => {
      isAuth() ? next() : next({ path: '/auth' });
    },
  },
  {
    path: '/test-resume',
    name: 'TestResume',
    props: true,
    component: () => import('@/views/Testing/components/TestingLastPage.vue'),
  },
  {
    path: '/middle-point',
    name: 'MiddlePoint',
    component: () => import('@/views/Testing/components/MiddlePoint.vue'),
  },
];
