export const onMetric = (data: Record<string, any>) => {
  if (import.meta.env.MODE !== 'development') {
    window.dataLayer = window.dataLayer || [];
    data.stream = 'app_smalltalk2';
    data.user_id = localStorage.getItem('userId');
    window.dataLayer.push(data);
  }
};

export const onMetricClick = ({ event, params }: { event: string; params?: Record<string, any> | undefined }) => {
  if (!params) {
    onMetric({ event });
  } else {
    onMetric({ event, ...params });
  }
};
