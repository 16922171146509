export default () => {
  const cookie = {} as Record<string, string>;
  document.cookie.split(';').forEach((el) => {
    const splitCookie = el.split('=');
    const key = splitCookie[0].trim();
    // eslint-disable-next-line prefer-destructuring
    cookie[key] = splitCookie[1];
  });
  // eslint-disable-next-line dot-notation
  const id = (cookie['_ga'] || '').substring(6);
  localStorage.setItem('gaClientId', id);
  return id;
};
