import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
  {
    path: '/work',
    name: 'WorkEnglish',
    meta: {
      title: 'English for Work',
    },
    component: () =>
      import(/* webpackChunkName: "legal" */ '@/views/Dashboard/views/WorkEnglish/views/WorkEnglish.vue'),
  },
];

export default routes;
